<template>
  <!--  考试信息-->
  <div class="exercisesType">
    <div style="display: flex; justify-content: space-between">
      <div class="menu_select">
        <h4
          style="
            height: 40px;
            padding-left: 24px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
          "
        >
          模块
        </h4>
        <a-menu style="width: 100%" mode="inline" v-model="menu">
          <a-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            @click="leftChange(item.id)"
            >{{ item.name }}</a-menu-item
          >
        </a-menu>
      </div>
      <div class="InfoTable">
        <div class="navTop">
          <div>
            年份：
            <a-date-picker
              mode="year"
              v-model="year"
              placeholder="请选择年份"
              format="YYYY"
              style="width: 200px"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"
            />
          </div>

          <div>
            标题：
            <a-input
              style="width: 200px"
              placeholder="请输入"
              v-model="title"
            ></a-input>
          </div>
          <div>
            <a-radio-group
              default-value="0"
              @change="callback($event)"
              v-model="state"
            >
              状态：
              <a-radio-button value="0"> 已发布</a-radio-button>
              <a-radio-button value="1"> 已置顶 </a-radio-button>
              <a-radio-button value="2"> 未发布</a-radio-button>
            </a-radio-group>
          </div>
          <div>
            <a-button type="primary" @click="search()">查询</a-button>
            <a-button style="margin-left: 10px" @click="returnBtn"
              >重置</a-button
            >
          </div>
        </div>
        <div class="nav">
          <a-button type="primary" style="margin: 15px 15px" @click="add"
            >新增</a-button
          >
          <a-table
            :scroll="{ x: 900 }"
            :columns="columns"
            :data-source="messageList"
            :rowKey="(record) => record.id"
            :pagination="false"
            style="margin: 15px"
          >
            <span slot="sort" slot-scope="text, record, index">
              {{ (pageNo - 1) * pageSum + index + 1 }}
            </span>
            <a
              slot="thisTitle"
              slot-scope="text, record, index, item"
              @click="toPdfUrl(record)"
            >
              {{ text }}
            </a>
            <span slot="operate" slot-scope="text, item, index, record">
              <a-popconfirm
                title="确认下架?"
                ok-text="是"
                cancel-text="否"
                @confirm="downRelease(0, item)"
              >
                <a style="margin-right: 10px" v-if="item.isRelease == 1"
                  >下架</a
                >
              </a-popconfirm>
              <a-popconfirm
                title="确认上架?"
                ok-text="是"
                cancel-text="否"
                @confirm="downRelease(1, item)"
              >
                <a style="margin-right: 10px" v-if="item.isRelease == 0"
                  >上架</a
                >
              </a-popconfirm>
              <a
                style="margin-right: 10px"
                @click="isTheTop(item.id)"
                v-if="item.isTop == 0"
                :disabled="item.isRelease == 0"
                >置顶</a
              >
              <a
                style="margin-right: 10px"
                @click="isTheTop(item.id)"
                v-if="item.isTop == 1"
                :disabled="item.isRelease == 0"
                >取消置顶</a
              >
              <a style="margin-right: 10px" @click="editBtn(item)">编辑</a>

              <!-- <a-popconfirm
                title="确认推荐吗?"
                ok-text="是"
                cancel-text="否"
                @confirm="toRecommend(item)"
              > -->
                <a @click="toRecommend(item)" style="margin-right: 10px" :disabled="item.isRelease == 0">推广</a>
              <!-- </a-popconfirm> -->
            </span>
          </a-table>
          <div
            style="
              margin-top: 28px;
              margin-left: 15px;
              margin-right: 15px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="
                font-size: medium;
                color: #929292;
                font-size: 14px;
                bottom: 3%;
              "
            >
              共{{ total }}条记录 第 {{ pageNo }} /
              {{ Math.ceil(total / pageSum) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="pageSum"
              :total="total"
              v-model="current"
              @change="pageChange"
              @showSizeChange="pageChange"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 修改-->
    <a-drawer
      title="新增/编辑"
      :width="500"
      :visible="addVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="addCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="addForm"
        ref="add"
        :rules="addRules"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input placeholder="请输入" v-model="addForm.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="副标题" prop="subtitle">
          <a-input placeholder="请输入" v-model="addForm.subtitle"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发表日期" prop="issueDate">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            style="width: 320px"
            placeholder="请选择"
            @change="onTimeChange"
            v-model="addForm.issueDate"
          />
        </a-form-model-item>
        <a-form-model-item
          label="上传文件"
          style="margin-bottom: -30px"
        >
        <div>
          <input
            style="
              z-index: 11;
              cursor: pointer;
              width: 120px;
              height: 40px;
              opacity: 0;
              position: absolute;
              top: 0;
            "
            type="file"
            @change="addFile"
            ref="pdfUrl"
            class="fileAdd"
            accept=".pdf"
            id="inputTest"
          />
        </div>
          <div class="uploadVideo"><a-icon type="upload" /> 上传文件</div>
          <div style="width: 150px; font-size: 12px">请上传pdf格式的文件</div>
          <!-- <div style="font-size: 8px;width: 600px">{{addForm.pdfUrl}}</div> -->
        </a-form-model-item>
        <a-form-model-item prop="pdfUrl">
          <div class="fileCard">
            <div style="color: red" v-if="fileType == '上传中'">
              上传中......
            </div>
            <div v-if="fileType == '' && addForm.pdfUrl">
              {{ addForm.pdfUrl }}
            </div>
            <a-input
              v-model="addForm.pdfUrl"
              placeholder="请输入"
              v-show="false"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="addCancel"> 取消 </a-button>
        <a-button
          style="margin-left: 10px"
          type="primary"
          @click="addOK"
          v-if="buttonType == 'newBuild'"
        >
          确定
        </a-button>
        <a-button
          style="margin-left: 10px"
          type="primary"
          @click="editOK"
          v-if="buttonType == 'edit'"
        >
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 推荐 -->
    <a-drawer
      title="推广"
      :width="500"
      :visible="promotionsVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="promotionsClose"
    >
      <div style="margin:20px">
        <a-table :pagination="false" :columns="appColumns" :data-source="appData" :row-key="record => record.code" bordered>
          <span slot="is_open" slot-scope="text, record">  
            <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="EXAMRecommend(record)" default-checked />
            <!-- 需要后端写一个查询 -->
            <!-- <a-switch v-if="record.title == '社区首页'" v-model="record.is_open" checked-children="是" un-checked-children="否" @click="communityListSwitch(record.is_open,record)" default-checked />
            <a-switch v-else v-model="record.is_open" checked-children="是" un-checked-children="否" @click="AppListSwitch(record.is_open,record)" default-checked /> -->
          </span>
        </a-table>
      </div>
      <div style="margin:20px">
         <a-table :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
           <span slot="is_open" slot-scope="text, record">
              <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
            </span>
         </a-table>
       </div>
    </a-drawer>
  </div>
</template>

<script>
import moment from "moment";
import { update_File } from "../../../utils/update"; //上传文件的工具
import { current_date } from "@/utils/currentDate";
import {postminaRecommend,getminaRecommend,delminaRecommend,getExanRecommend,delExanRecommend} from "@/service/Recruiting";
import {
  getMoKuaiData,
  getExamChoiceness,
  postChoiceness,
  putChoiceness,
  putRelease,
  putTop,
  postRecommend,
} from "@/service/titleExam_api";
export default {
  name: "exercisesType",
  data() {
    return {
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      appData:[
        {
          id:1,
          title:'职称考试首页',
          is_open:false
        }
      ],
      appColumns:[
        {
          title: '模块',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      promotionsVisible:false,
      buttonType: "",
      current: 1,
      fileType: "",
      menuList: [],
      messageList: [],
      year: moment(current_date()).format("YYYY"),
      yearShowOne: false,
      title: "",
      isRelease: 1, //发布
      isTop: "", //置顶
      state: "0",
      pageNo: 1,
      pageSum: 10,
      total: 0,
      id: "",
      menu: [0],
      addVisible: false,
      labelColLive: { span: 5 },
      wrapperCol: { span: 17 },
      addForm: {},
      columns: [
        {
          title: "序号",
          // width: "10%",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          scopedSlots: { customRender: "thisTitle" },
          // width: "15%",
        },
        {
          title: "副标题",
          dataIndex: "subtitle",
          // width: "18%",
        },
        {
          title: "发表日期",
          dataIndex: "issueDate",
          // width: "10%",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          width: 200,
        },
        {
          title: "操作",
          dataIndex: "sortIndex",
          width: 250,
          fixed: "right",
          scopedSlots: { customRender: "operate" },
        },
      ],
      addRules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        subtitle: [{ required: true, message: "请输入", trigger: "blur" }],
        pdfUrl: [{ required: true, message: "请上传", trigger: "change" }],
        issueDate: [{ required: true, message: "请输入", trigger: "change" }],
      },
      promotionId:'',
      examTypeId:'',
    };
  },
  created() {
    this.getMenu();
  },
  mounted() {},
  methods: {
    async EXAMRecommend(record){
      if(record.is_open === true){
        let data = {
          contentId: this.promotionId, //考点精选或者考试信息的id
          examTypeId: this.id, //职称模块id
          contentType: "choiceness", //考点精选：choiceness  考试信息  ： message
        };
        const res = await postRecommend(data);
        if (res.code == 0) {
          this.$message.success("操作成功");
          // this.leftChange(this.id);
        } else {
          this.$message.warn(res.message);
        }
      }else{
        const res = await delExanRecommend(this.promotionId,'choiceness',this.examTypeId);
        if (res.code == 0) {
          this.$message.success("操作成功");
          // this.leftChange(this.id);
        } else {
          this.$message.warn(res.message);
        }
      }
      // let data = {
      //   contentId: item.id,
      //   examTypeId: item.examTypeId,
      //   contentType: "choiceness",
      // };
      // const res = await postRecommend(data);
      // if (res.code == 0) {
      //   this.$message.success("推荐成功");
      //   this.getTable();
      // } else {
      //   this.$message.warn(res.message);
      // }
    },
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'EXAM',
          subContentType:"EXAM_CHOISE",
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'EXAM',
          subContentType:"EXAM_CHOISE",
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    promotionsClose(){
      this.promotionsVisible = false
      // this.appData = []
      // this.promotionId = null
      // this.getDocument()
      // this.subContentType = null
    },
    async getMenu() {
      let data = {
        page: 1,
        pageSize: 999,
      };
      const res = await getMoKuaiData(data);
      if (res.code == 200) {
        this.menuList = res.data;
        this.id = this.menuList[0].id;
        this.onloads();
      }
    },
    // 默认加载第一个模块内容
    async onloads() {
      let data = {
        examTypeId: this.id,
        year: this.year,
        title: this.title,
        isRelease: this.isRelease,
        isTop: this.isTop,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };
      const res = await getExamChoiceness(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    async getTable() {
      let data = {
        examTypeId: this.id,
        year: this.year,
        title: this.title,
        isRelease: this.isRelease,
        isTop: this.isTop,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };
      const res = await getExamChoiceness(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    //弹出日历和关闭日历的回调
    openChangeOne(status) {
      //status是打开或关闭的状态
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.year = value;
      this.yearShowOne = false;
      if (this.year) {
        this.year = moment(this.year).format("YYYY");
      }
    },
    callback(e) {
      let activeKey = e.target.value;
      switch (activeKey) {
        case "2": //未发布
          this.isRelease = 0;
          this.state = "2";
          this.isTop = "";
          this.getTable();
          break;
        case "1": //已置顶
          this.isTop = 1;
          this.state = "1";
          this.isRelease = "";
          this.getTable();
          break;
        case "0": //已发布
          this.isRelease = 1;
          this.isTop = "";
          (this.state = "0"), this.getTable();
          break;
        default:
          break;
      }
    },
    search() {
      this.getTable(this.id);
    },
    // 点击左侧菜单获取内容
    async leftChange(id) {
      this.id = id;
      let data = {
        examTypeId: this.id,
        year: this.year,
        title: this.title,
        isRelease: this.isRelease,
        isTop: this.isTop,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };
      console.log(data);
      const res = await getExamChoiceness(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    add() {
      this.buttonType = "newBuild";
      this.addVisible = true;
    },
    onTimeChange(date, dateString) {
      this.addForm.issueDate = dateString;
    },
    addCancel() {
      this.addForm = {};
      this.addVisible = false;
      this.fileType = "";
    },
    async addOK() {
      (this.addForm.examTypeId = this.id), console.log("表单", this.addForm);
      //    this.addForm.pdfUrl = "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/file/d2b1bed3-af27-48ca-a2ff-d3a438a13a0c.pdf"
      this.$refs.add.validate(async (valid) => {
        // 表单校验
        if (valid) {
          console.log("表单", this.addForm);
          const res = await postChoiceness(this.addForm);
          if (res.code == 0) {
            this.$message.success("添加成功！");
            this.pageNo = 1;
            this.pageSum = 10;
            this.getTable();
            this.addVisible = false;
            this.addForm = {};
          }
        } else {
          this.$message.error("请填写完整");
        }
      });
    },
    async editOK() {
      (this.addForm.examTypeId = this.id),
        //    this.addForm.pdfUrl = "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/file/d2b1bed3-af27-48ca-a2ff-d3a438a13a0c.pdf"
        this.$refs.add.validate(async (valid) => {
          // 表单校验
          if (valid) {
            //  console.log("修改表单",this.addForm)
            const res = await putChoiceness(this.addForm);
            if (res.code == 0) {
              this.$message.success("编辑成功！");
              this.pageNo = 1;
              this.pageSum = 10;
              this.getTable();
              this.addVisible = false;
              this.addForm = {};
            }
          } else {
            this.$message.error("请填写完整");
          }
        });
    },
    // 上传文件
    async addFile() {
      var inputTest = document.querySelector("#inputTest");
      let file = this.$refs.pdfUrl.files[0];
      this.fileType = "上传中";
      if (file !== undefined) {
        let newVar = await update_File(file);
        if (newVar.code === 0) {
          this.addForm.pdfUrl = newVar.data.url;
          console.log("文件url", this.addForm.pdfUrl);
          this.$message.success("文件上传成功");
          this.fileType = "";
          inputTest.value = "";
        } else {
          this.$message.error("上传文件失败" + newVar.message);
        }
      }
    },
    // 修改表单
    async editBtn(item) {
      //  // 如果修改表单的时候，外面的table也跟着变，需要重新加载table（但是不知道为什么）
      //  this.getTable()
      this.addVisible = true;
      this.buttonType = "edit";
      // console.log("item",item)
      this.addForm = JSON.parse(JSON.stringify(item)); //content和备注
      //  this.itemId = item.id
      //    this.addForm.provinceCity.push(item.province)
      //    this.addForm.provinceCity.push(item.city)
    },
    // 开pdf
    toPdfUrl(record) {
      // console.log("单个item",record)
      window.open(record.pdfUrl);
    },
    // 分页查询
    pageChange(page_no, page_size) {
      this.pageNo = page_no;
      this.pageSum = page_size;
      this.getTable();
    },
    // 上架和下架
    async downRelease(a, record) {
      const res = await putRelease(record.id, a);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.getTable();
      }
      // 下架需要同时取消置顶 a=0是点下架
      if (a == 0 && record.isTop == 1) {
        const res2 = await putTop(record.id);
        if (res2.code == 0) {
          this.$message.success("取消置顶");
          //   this.getTable()
        }
      }
    },
    // 置顶
    async isTheTop(id) {
      const res = await putTop(id);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.getTable();
      }
    },
    // 重置
    returnBtn() {
      this.title = ""; //输入框
      this.current = 1; //默认显示
      this.pageSum = 10; //行数
      this.pageNo = 1;
      this.year = moment(current_date()).format("YYYY");
      this.state = "0";
      this.isTop = "";
      this.isRelease = 1;
      this.menu = [0];
      this.id = this.menuList[0].id;
      this.onloads();
      this.getTable();
    },
    // 推荐
    async toRecommend(item) {
      this.promotionsVisible = true
      this.promotionId = item.id
      this.examTypeId = item.examTypeId
      this.minaRecord()
      // 推荐回显
      this.ExamRecord()
    },
    // 职称考试首页推荐  
    async ExamRecord(){
      const response = await getExanRecommend(this.promotionId,'choiceness',this.examTypeId)
      if (response.code === 0) {
        if(response.data == 1){
           this.appData[0].is_open = true
        }else{
          this.appData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    //  小程序推广回显
    async minaRecord(){
      // 小程序推广回显  
      const data = {
        contentId:this.promotionId,
        contentType:'EXAM',
        subContentType:"EXAM_CHOISE",
      }
      const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.uniAppData[0].is_open = true
        }else{
          this.uniAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exercisesType {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .menu_select {
    background-color: white;
    width: 18%;
    min-height: 750px;
  }
  .InfoTable {
    background-color: white;
    width: 81%;
    min-height: 750px;
    .navTop {
      width: 100%;
      height: 100px;
      border-bottom: 15px solid rgba(240, 242, 245, 1);
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .nav {
      width: 100%;
    }
  }
}
.uploadVideo {
  border: #dddddd 1px solid;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  height: 40px;
  cursor: pointer;
  position: relative;
}
.fileAdd {
  width: 80px;
  height: 25px;
  opacity: 0;
}
.fileCard {
  width: 278px;
  margin-left: 28%;
  margin-top: 0;
}
</style>
